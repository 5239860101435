import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  Modal
} from "react-bootstrap";

import AuthenContext from "../models/AuthenContext";
import ButtonLoading from "../components/Controls/ButtonLoading";

function Password() {
  const {authen, setIsLogin} = useContext(AuthenContext);

  const [alert, setAlert] = useState({
    "show": false,
    "text": ""
  });

  const initForm = {
    "password": "",
    "re_password": ""
  };

  const [form, setForm] = useState(initForm);
  const [formError, setFormError] = useState(initForm);
  const [loadingSave, setLoadingSave] = useState(false);

  useEffect(() => {
    
  }, []);

  const handleFormInputChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }

  const formSubmit = (e) => {
    e.preventDefault();

    let is_valid = true;
    let errors = initForm;

    if(form.password.length < 6) {
      is_valid = false;
      errors.password = "กรุณาป้อน Password อย่างน้อย 6 ตัวอักษร";
    }
    if(form.re_password == "") {
      is_valid = false;
      errors.re_password = "กรุณาป้อน Password อีกครั้ง";
    }
    else if(form.password != form.re_password) {
      is_valid = false;
      errors.re_password = "ป้อน Password อีกครั้งไม่ตรงกับ Password";
    }

    if(is_valid) {
      setLoadingSave(true);

      axios.post(process.env.REACT_APP_API_URL + "/school/password.set", {
        "api_key": process.env.REACT_APP_API_KEY,
        "token": authen.token,
        ...form
      })
      .then((res) => {
        let { status, data } = res.data;
        if(status == 1) {
          setForm(initForm);
          setFormError(initForm);
        }

        setAlert({
          "show": true,
          "text": res.data.msg
        });
      })
      .catch((ex) => {
        console.log(ex);
      })
      .finally(() => {
        setLoadingSave(false);
      });
    }
    else {
      setFormError({
        ...formError,
        ...errors
      });
    }
  }

  return (
    <>
      <Container>
        <Row className="justify-content-md-center align-items-center">
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4" className="text-center">เปลี่ยนรหัสผ่าน</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form noValidate onSubmit={formSubmit}>
                  <Form.Group>
                    <label>Password</label>
                    <Form.Control type="password" name="password" value={form.password} placeholder="อย่างน้อย 6 ตัวอักษร" onChange={handleFormInputChange} isValid={form.password && !formError.password} isInvalid={!!formError.password}></Form.Control>
                    <Form.Control.Feedback type="invalid">{formError.password}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <label>ป้อน Password อีกครั้ง</label>
                    <Form.Control type="password" name="re_password" value={form.re_password} placeholder="ป้อนรหัสผ่านอีกครั้ง" onChange={handleFormInputChange} isValid={form.re_password && !formError.re_password} isInvalid={!!formError.re_password}></Form.Control>
                    <Form.Control.Feedback type="invalid">{formError.re_password}</Form.Control.Feedback>
                  </Form.Group>
                  <ButtonLoading type="submit" icon="fas fa-key" className="btn btn-info btn-fill btn-block" loading={loadingSave}>เปลี่ยนรหัสผ่าน</ButtonLoading>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {
        alert.show && <SweetAlert
                        title={alert.text}
                        onConfirm={() => setAlert({"show": false})}
                        btnSize="md"
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}} />
      }
    </>
  );
}

export default Password;
