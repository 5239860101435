import React, { useState, useEffect, forwardRef } from "react";

const Draw = forwardRef(({id, width, height, clearDraw, setClearDraw, color, className, style, onDrawingEnd}, ref) => {
  const [Canvas, setCanvas] = useState({
    "clear": () => null
  });

  useEffect(() => {
    // =============
    // == Globals ==
    // =============
    const canvas = document.getElementById(id);
    const canvasContext = canvas.getContext('2d');
    const state = {
      mousedown: false
    };

    // ===================
    // == Configuration ==
    // ===================
    const lineWidth = 5;
    const strokeStyle = color === undefined ? '#000' : color;

    // =====================
    // == Event Listeners ==
    // =====================
    canvas.addEventListener('mousedown', handleWritingStart);
    canvas.addEventListener('mousemove', handleWritingInProgress);
    canvas.addEventListener('mouseup', handleDrawingEnd);
    canvas.addEventListener('mouseout', handleDrawingEnd);

    canvas.addEventListener('touchstart', handleWritingStart);
    canvas.addEventListener('touchmove', handleWritingInProgress);
    canvas.addEventListener('touchend', handleDrawingEnd);

    // ====================
    // == Event Handlers ==
    // ====================
    function handleWritingStart(event) {
      event.preventDefault();

      const mousePos = getMosuePositionOnCanvas(event);
      
      canvasContext.beginPath();

      canvasContext.moveTo(mousePos.x, mousePos.y);

      canvasContext.lineWidth = lineWidth;
      canvasContext.strokeStyle = strokeStyle;
      canvasContext.shadowColor = null;
      canvasContext.shadowBlur = null;

      canvasContext.fill();
      
      state.mousedown = true;
    }

    function handleWritingInProgress(event) {
      event.preventDefault();
      
      if (state.mousedown) {
        const mousePos = getMosuePositionOnCanvas(event);

        canvasContext.lineTo(mousePos.x, mousePos.y);
        canvasContext.stroke();
      }
    }

    function handleDrawingEnd(event) {
      event.preventDefault();
      
      if (state.mousedown) {
        canvasContext.stroke();
        if(onDrawingEnd !== undefined) {
          onDrawingEnd(event);
        }
      }
      
      state.mousedown = false;
    }

    // ======================
    // == Helper Functions ==
    // ======================
    function getMosuePositionOnCanvas(event) {
      return { x: event.offsetX, y: event.offsetY };
    }

    setCanvas({"clear": () => {
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);
    }});
  }, []);

  useEffect(() => {
    if(clearDraw) {
      Canvas.clear();
      setClearDraw(false);
    }
  }, [clearDraw]);

  return (
    <canvas id={id} width={width} height={height} className={className} style={style} ref={ref}></canvas>
  );
});

export default Draw;
