import React, { Component } from "react";
import { useLocation, Route, Switch, generatePath } from "react-router-dom";

import adminRoutes from "routes.js";
import userRoutes from "routes_user.js";

import AdminLayout from "layouts/Admin.js";
import UserLayout from "layouts/User.js";
import BlankLayout from "layouts/Blank.js";

function Select(props) {
  let routes;
  if(props.base === "user") {
    routes = userRoutes;
  }
  else {
    routes = adminRoutes;
  }

  const getRoute = (props) => {
    let route = routes.find(e => {
      let path = e.path;
      if(Object.keys(props.match.params).length > 0) {
        path = generatePath(e.path, props.match.params);
      }
      return path === props.location.pathname;
    });
    
    if(route === undefined) {
      return null;
    }
    else if(route.layout === "/admin") {
      return (
        <Route
          path={props.match.path}
          render={(props) => <AdminLayout {...props} routes={routes} />}
        />
      );
    }
    else if(route.layout === "/user") {
      return (
        <Route
          path={props.match.path}
          render={(props) => <UserLayout {...props} routes={routes} />}
        />
      );
    }
    else if(route.layout === "/blank") {
      return (
        <Route
          path={props.match.path}
          render={(props) => <BlankLayout {...props} routes={routes} />}
        />
      );
    }
    else {
      return null;
    }
  };
  return (
    <>
      <Switch>{getRoute(props)}</Switch>
    </>
  );
}

export default Select;
