import React, { useState, useContext } from "react";

import AuthenContext from "../../models/AuthenContext";

function Logout() {
  const {authenUser, setIsLoginUser} = useContext(AuthenContext);
  localStorage.removeItem("authenUser");
  setIsLoginUser(false);

  return null;
}

export default Logout;
