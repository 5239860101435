import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, useParams, generatePath } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import ChartistGraph from "react-chartist";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import AuthenContext from "../../models/AuthenContext";

function Dashboard() {
  const history = useHistory();

  const {authenUser, setIsLoginUser} = useContext(AuthenContext);

  const [alert, setAlert] = useState({
    "show": false,
    "text": ""
  });

  const [docStat, setDocStat] = useState({
    "total": "",
    "in": "",
    "unread": "",
    "complete": ""
  });
  const [tableDoc, setTableDoc] = useState([]);

  useEffect(() => {
    loadDocStat();
    loadTableDoc();
  }, []);

  const loadDocStat = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/docuser.stat", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setDocStat(data);
      }
      else if(status == -1) {
        setIsLogin(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadTableDoc = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/doc.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authenUser.token,
      "limit": 10
    })
    .then((res) => {
      let { status, data, page } = res.data;
      if(status == 1) {
        setTableDoc(data.map((row) => {
          return {...row, loadingEdit: false, loadingDelete: false};
        }));
      }
      else if(status == -1) {
        setIsLoginUser(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const viewDoc = (e) => {
    history.push("doc");
  }

  const viewDocIn = (e) => {
    history.push("docin");
  }

  return (
    <>
      <Container fluid>
        <Row className="dashboard-user">
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <div className="text-center">
                  <p className="card-category">เอกสารทั้งหมด</p>
                  <Card.Title as="h4">{docStat.total}</Card.Title>
                </div>
              </Card.Body>
              <Card.Footer></Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <div className="text-center">
                  <p className="card-category">เอกสารได้รับ</p>
                  <Card.Title as="h4">{docStat.in}</Card.Title>
                </div>
              </Card.Body>
              <Card.Footer></Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <div className="text-center">
                  <p className="card-category">เอกสารที่ไม่ได้อ่าน</p>
                  <Card.Title as="h4">{docStat.unread}</Card.Title>
                </div>
              </Card.Body>
              <Card.Footer className="text-center">
                <Button type="button" variant="danger" className="btn-fill" onClick={viewDocIn}>คลิกเพื่ออ่าน</Button>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <div className="text-center">
                  <p className="card-category">เอกสารเสร็จสิ้น</p>
                  <Card.Title as="h4">{docStat.complete}</Card.Title>
                </div>
              </Card.Body>
              <Card.Footer></Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="card-tasks">
              <Card.Header>
                <Card.Title as="h4">เอกสารล่าสุด</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="table-full-width">
                  <Table>
                    <thead>
                      <tr>
                        <th>เรื่อง</th>
                        <th>ผู้ส่ง</th>
                        <th>สถานะ</th>
                        <th>วัน - เวลา</th>
                        <th>แสดงความคิดเห็น</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableDoc.map((row, index) => (  
                        <tr>
                          <td>{row.doc_name}</td>
                          <td>{row.created_by}</td>
                          <td className="text-center"><Badge variant={row.doc_status_variant}>{row.doc_status_text}</Badge></td>
                          <td className="text-center">{row.created_at}</td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
              <Card.Footer className="text-right">
                <hr></hr>
                <div>
                  <Button type="button" variant="primary" className="btn-fill" onClick={viewDoc}>ดูเอกสารทั้งหมด</Button>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>

      {
        alert.show && <SweetAlert
                        title={alert.text}
                        onConfirm={() => setAlert({"show": false})}
                        btnSize="md"
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}} />
      }
    </>
  );
}

export default Dashboard;
