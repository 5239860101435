import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, useParams, generatePath } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  Modal
} from "react-bootstrap";

import AuthenContext from "../models/AuthenContext";
import ButtonLoading from "../components/Controls/ButtonLoading";
import Page from "../components/Controls/Page";

function Choice(props) {
  const history = useHistory();
  const params = useParams();

  const {authen, setIsLogin} = useContext(AuthenContext);

  const [showForm, setShowForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formTitle, setFormTitle] = useState("");

  const [alert, setAlert] = useState({
    "show": false,
    "text": ""
  });

  const [confirm, setConfirm] = useState({
    "show": false,
    "text": "",
    "confirmBtnText": "",
    "onConfirm": () => {},
    "cancelBtnText": "",
    "onCancel": () => {}
  });

  const [search, setSearch] = useState({
    "school_choice_id": ""
  });

  const handleSearchInputChange = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value
    });

    setPages({
      ...pages,
      "activePage": 1
    });
  }

  const initForm = {
    "school_choice_id": "",
    "choice_name": ""
  };

  let activePage = new URLSearchParams(props.location.search).get("page");
  activePage = activePage === null ? 1 : parseInt(activePage);

  const [locationKeys, setLocationKeys] = useState([]);
  const [table, setTable] = useState([]);
  const [pages, setPages] = useState({
    "activePage": activePage
  });

  const handlePageChange = (e, page) => {
    history.push("?page=" + page);
  }

  const [form, setForm] = useState(initForm);
  const [loadingSave, setLoadingSave] = useState(false);

  const [choice, setChoice] = useState([]);

  useEffect(() => {
    loadChoice();
  }, []);

  useEffect(() => {
    loadTable();
  }, [search, pages.activePage]);

  useEffect(() => {
    setPages({
      ...pages,
      "activePage": activePage
    });

    return history.listen(location => {
      if(history.action === "PUSH") {
        setLocationKeys([location.key]);
      }
  
      if(history.action === "POP") {
        if(locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          // Handle forward event
        }
        else {
          setLocationKeys((keys) => [location.key, ...keys]);
          // Handle back event
        }
      }
    });
  }, [locationKeys]);

  const handleFormInputChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }

  const loadTable = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/choice.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authen.token,
      "school_choice_id": search.school_choice_id,
      "order": "choice_name",
      "limit": 1000
    })
    .then((res) => {
      let { status, data, page } = res.data;
      if(status == 1) {
        setPages(page);
        setTable(data.map((row) => {
          if(row.choice_data) {
            row.choice_data.map((row2) => {
              if(row2.children) {
                row2.children.map((row3) => {
                  return {...row3, loadingAdd: false, loadingEdit: false, loadingDelete: false};
                });
              }
              return {...row2, loadingAdd: false, loadingEdit: false, loadingDelete: false};
            });
          }
          return row;
        }));
      }
      else if(status == -1) {
        setIsLogin(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const addData = (e, index) => {
    e.preventDefault();

    let level = 1, school_choice_id = search.school_choice_id;
    if(index != "") {
      let row, indexs = index.split("-");
      for(let i in indexs) {
        if(i == 0) row = table[indexs[i]].choice_data;
        else if(i == 1) row = row[indexs[i]];
        else row = row.children[indexs[i]];
      }
      row.loadingAdd = true;
      setTimeout(() => row.loadingAdd = false, 100);
      level = indexs.length;
      school_choice_id = table[indexs[0]].id;
    }
    
    setForm({
      ...initForm,
      "school_choice_id": school_choice_id,
      "level": level,
      "index": index,
      "do": "add"
    });
    setValidated(false);
    setFormTitle("เพิ่มตัวเลือก");
    setShowForm(true);
  }

  const editData = (e, index) => {
    e.preventDefault();

    let row, level = 1, school_choice_id = search.school_choice_id;
    if(index != "") {
      let indexs = index.split("-");
      for(let i in indexs) {
        if(i == 0) row = table[indexs[i]].choice_data;
        else if(i == 1) row = row[indexs[i]];
        else row = row.children[indexs[i]];
      }
      row.loadingEdit = true;
      setTimeout(() => row.loadingEdit = false, 100);
      level = indexs.length;
      school_choice_id = table[indexs[0]].id;
    }

    setForm({
      ...initForm,
      "school_choice_id": school_choice_id,
      "choice_name": row.choice_name,
      "level": level,
      "index": index,
      "do": "edit"
    });
    setValidated(false);
    setFormTitle("แก้ไขตัวเลือก");
    setShowForm(true);
  }

  const deleteData = (e, index) => {
    e.preventDefault();

    let row, level = 1, school_choice_id = search.school_choice_id;
    if(index != "") {
      let indexs = index.split("-");
      for(let i in indexs) {
        if(i == 0) row = table[indexs[i]].choice_data;
        else if(i == 1) row = row[indexs[i]];
        else row = row.children[indexs[i]];
      }
      row.loadingDelete = true;
      level = indexs.length;
      school_choice_id = table[indexs[0]].id;
    }

    let _form = {
      ...initForm,
      "school_choice_id": school_choice_id,
      "choice_name": row.choice_name,
      "level": level,
      "index": index,
      "do": "delete"
    };
    setForm(_form);

    setConfirm({
      "show": true,
      "text": `ต้องการลบ "${row.choice_name}" ใช่หรือไม่`,
      "confirmBtnText": "ลบ",
      "onConfirm": () => {
        axios.post(process.env.REACT_APP_API_URL + "/school/choice.data.set", {
          "api_key": process.env.REACT_APP_API_KEY,
          "token": authen.token,
          ..._form
        })
        .then((res) => {
          let { status, data } = res.data;
          if(status == 1) {
            loadTable();
          }
          else if(status == -1) {
            setIsLogin(false);
          }
          else {
            setAlert({
              "show": true,
              "text": res.data.msg
            });
          }
        })
        .catch((ex) => {
          console.log(ex);
          setAlert({
            "show": true,
            "text": process.env.REACT_APP_SERVER_NOT_CONNECT
          });
        })
        .finally(() => {
          row.loadingDelete = false;
        });

        setConfirm({"show": false});
      },
      "cancelBtnText": "ยกเลิก",
      "onCancel": () => {
        setConfirm({"show": false});
        row.loadingDelete = false;
      }
    });
  }

  const moveSubmit = (action, index) => {
    let row, level = 1, school_choice_id = search.school_choice_id;
    if(index != "") {
      let indexs = index.split("-");
      for(let i in indexs) {
        if(i == 0) row = table[indexs[i]].choice_data;
        else if(i == 1) row = row[indexs[i]];
        else row = row.children[indexs[i]];
      }
      row.loadingDelete = true;
      level = indexs.length;
      school_choice_id = table[indexs[0]].id;
    }

    let _params = {
      ...initForm,
      "school_choice_id": school_choice_id,
      "choice_name": row.choice_name,
      "level": level,
      "index": index,
      "do": action
    };

    axios.post(process.env.REACT_APP_API_URL + "/school/choice.data.set", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authen.token,
      ..._params
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        loadTable();
      }
      else if(status == -1) {
        setIsLogin(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    })
    .finally(() => {
      
    });
  }

  const moveUpData = (e, index) => {
    e.preventDefault();
    moveSubmit("moveup", index);
  }

  const moveDownData = (e, index) => {
    e.preventDefault();
    moveSubmit("movedown", index);
  }

  const formSubmit = (e) => {
    e.preventDefault();

    let is_valid = false;
    if(e.currentTarget.checkValidity() === false) {
      is_valid = false;
      e.stopPropagation();
    }
    else {
      is_valid = true;
    }

    setValidated(true);

    if(is_valid) {
      setLoadingSave(true);

      axios.post(process.env.REACT_APP_API_URL + "/school/choice.data.set", {
        "api_key": process.env.REACT_APP_API_KEY,
        "token": authen.token,
        ...form
      })
      .then((res) => {
        let { status, data } = res.data;
        if(status == 1) {
          loadTable();
          setShowForm(false);
        }
        else {
          setAlert({
            "show": true,
            "text": res.data.msg
          });
        }
      })
      .catch((ex) => {
        console.log(ex);
      })
      .finally(() => {
        setLoadingSave(false);
      });
    }
  }

  const loadChoice = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/choice.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authen.token,
      "order": "choice_name",
      "limit": 1000
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setChoice(data);
      }
      else if(status == -1) {
        setIsLogin(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  let no = 0;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Row>
                  <Col md="6">
                    <Form inline>
                      <Form.Group>
                        <Form.Label className="mr-sm-2">รูปแบบ</Form.Label>
                        <Form.Control as="select" name="school_choice_id" value={search.school_choice_id} onChange={handleSearchInputChange} required>
                          <option value="">- ทั้งหมด -</option>
                          {choice.map((row, index) => <option value={row.id} key={index}>{row.choice_name}</option>)}
                        </Form.Control>
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col md="6">
                    <Button type="button" variant="info" className="btn-fill float-right" onClick={e => addData(e, "")}><i className="fas fa-plus"></i> เพิ่มตัวเลือก</Button>
                    <Button type="button" variant="info" className="btn-fill float-right mr-2" onClick={e => history.push(generatePath(process.env.REACT_APP_ADMIN_PATH, params) + "/choicegroup")}><i className="far fa-folder"></i> จัดการรูปแบบ</Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th width="80"></th>
                      <th width="80">ลำดับ</th>
                      <th width="200">รูปแบบ</th>
                      <th>ตัวเลือก</th>
                      <th>ตัวเลือกย่อย</th>
                      <th width="250"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {table.map((row, index) => (
                      row.choice_data && <>
                        {row.choice_data.map((row2, index2) => (
                          <>
                            <tr key={index + "-" + index2}>
                              <td className="text-center">
                                {index2 != 0 && <a href="#" onClick={e => moveUpData(e, index + "-" + index2)}><i class="fas fa-caret-up i-ud"></i></a>}
                                {index2 < row.choice_data.length - 1 && <a href="#" onClick={e => moveDownData(e, index + "-" + index2)}><i class="fas fa-caret-down i-ud"></i></a>}
                              </td>
                              <td className="text-center">{++no}</td>
                              <td>{index2 == 0 && row.choice_name}</td>
                              <td>{row2.choice_name}</td>
                              <td></td>
                              <td className="text-right btn-manage-group">
                                <ButtonLoading type="link" icon="fas fa-plus" className="btn-manage" loading={row2.loadingAdd} onClick={e => addData(e, index + "-" + index2)}>เพิ่มตัวเลือกย่อย</ButtonLoading>
                                <ButtonLoading type="link" icon="fas fa-pen" className="btn-manage" loading={row2.loadingEdit} onClick={e => editData(e, index + "-" + index2)}>แก้ไข</ButtonLoading>
                                <ButtonLoading type="link" icon="fas fa-times" className="btn-manage" loading={row2.loadingDelete} onClick={e => deleteData(e, index + "-" + index2)}>ลบ</ButtonLoading>
                              </td>
                            </tr>
                            {row2.children && row2.children.map((row3, index3) => (
                              <tr key={index + "-" + index2 + "-" + index3}>
                                <td className="text-center">
                                  {index3 != 0 && <a href="#" onClick={e => moveUpData(e, index + "-" + index2 + "-" + index3)}><i class="fas fa-caret-up i-ud"></i></a>}
                                  {index3 < row2.children.length - 1 && <a href="#" onClick={e => moveDownData(e, index + "-" + index2 + "-" + index3)}><i class="fas fa-caret-down i-ud"></i></a>}
                                </td>
                                <td className="text-center">{++no}</td>
                                <td></td>
                                <td></td>
                                <td>{row3.choice_name}</td>
                                <td className="text-right btn-manage-group">
                                  <ButtonLoading type="link" icon="fas fa-pen" className="btn-manage" loading={row3.loadingEdit} onClick={e => editData(e, index + "-" + index2 + "-" + index3)}>แก้ไข</ButtonLoading>
                                  <ButtonLoading type="link" icon="fas fa-times" className="btn-manage" loading={row3.loadingDelete} onClick={e => deleteData(e, index + "-" + index2 + "-" + index3)}>ลบ</ButtonLoading>
                                </td>
                              </tr>
                            ))}
                          </>
                        ))}
                      </>
                    ))}
                  </tbody>
                </Table>
                <Page {...pages} onClick={handlePageChange} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        backdrop="static"
        show={showForm}
        onHide={() => setShowForm(false)}
      >
        <Form noValidate validated={validated} onSubmit={formSubmit}>
          <Modal.Header className="justify-content-center">
            <Modal.Title>{formTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {form.level == 1 && <Form.Group as={Row}>
              <Form.Label column sm="2">รูปแบบ</Form.Label>
              <Col sm="10">
                <Form.Control as="select" name="school_choice_id" value={form.school_choice_id} onChange={handleFormInputChange} required>
                  <option value=""></option>
                  {choice.map((row, index) => <option value={row.id} key={index}>{row.choice_name}</option>)}
                </Form.Control>
                <Form.Control.Feedback type="invalid">กรุณาป้อน รูปแบบ</Form.Control.Feedback>
              </Col>
            </Form.Group>}
            <Form.Group as={Row}>
              <Form.Label column sm="2">ตัวเลือก</Form.Label>
              <Col sm="10">
                <Form.Control type="text" name="choice_name" value={form.choice_name} onChange={handleFormInputChange} required></Form.Control>
                <Form.Control.Feedback type="invalid">กรุณาป้อน ตัวเลือก</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <ButtonLoading type="submit" icon="fas fa-check" className="btn btn-primary btn-fill mr-sm-2" loading={loadingSave}>บันทึก</ButtonLoading>
            <Button type="button" variant="default" className="btn-fill" onClick={() => setShowForm(false)}><i className="fas fa-times"></i> ปิด</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {
        alert.show && <SweetAlert
                        title={alert.text}
                        onConfirm={() => setAlert({"show": false})}
                        btnSize="md"
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}} />
      }
      {
        confirm.show && <SweetAlert
                        title={confirm.text}
                        btnSize="md"
                        onConfirm={confirm.onConfirm}
                        confirmBtnText={confirm.confirmBtnText}
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}}
                        showCancel={true}
                        onCancel={confirm.onCancel}
                        cancelBtnText={confirm.cancelBtnText}
                        cancelBtnBsStyle="default"
                        cancelBtnCssClass="btn-fill"
                        cancelBtnStyle={{"border":"none","boxShadow":"none"}} />
      }
    </>
  );
}

export default Choice;
