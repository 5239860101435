import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  Modal
} from "react-bootstrap";

import AuthenContext from "../models/AuthenContext";
import ButtonLoading from "../components/Controls/ButtonLoading";

function Style() {
  const {authen, setIsLogin} = useContext(AuthenContext);

  const [validated, setValidated] = useState(false);

  const [alert, setAlert] = useState({
    "show": false,
    "text": ""
  });

  const initForm = {
    "style_name": "",
    "style_btn_color": "",
    "style_cover": { "url": "" },
    "style_footer": { "url": "" }
  };

  const [form, setForm] = useState(initForm);
  const [loadingSave, setLoadingSave] = useState(false);

  useEffect(() => {
    loadEdit();
  }, []);

  const handleFormInputChange = (e) => {
    let key = e.target.name, value;
    
    if(e.target.type == "file") {
      let file = e.target.files[0];
      let fr = new FileReader();
      fr.onload = () => {
        value = {
          "name": file.name,
          "size": file.size,
          "type": file.type,
          "base64": fr.result,
          "url": URL.createObjectURL(file)
        };

        setForm({
          ...form,
          [key]: value
        });
      };
      fr.readAsDataURL(file);
      return;
    }
    else {
      value = e.target.value;
    }

    setForm({
      ...form,
      [key]: value
    });
  }

  const loadEdit = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/style.get", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authen.token
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setForm(data);
        setValidated(false);
      }
      else if(status == -1) {
        setIsLogin(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    })
    .finally(() => {
      
    });
  }

  const formSubmit = (e) => {
    e.preventDefault();

    let is_valid = false;
    if(e.currentTarget.checkValidity() === false) {
      is_valid = false;
      e.stopPropagation();
    }
    else {
      is_valid = true;
    }

    setValidated(true);

    if(is_valid) {
      setLoadingSave(true);

      axios.post(process.env.REACT_APP_API_URL + "/school/style.set", {
        "api_key": process.env.REACT_APP_API_KEY,
        "token": authen.token,
        ...form
      })
      .then((res) => {
        let { status, data } = res.data;
        if(status == 1) {
          //loadEdit();
        }

        setAlert({
          "show": true,
          "text": res.data.msg
        });
      })
      .catch((ex) => {
        console.log(ex);
      })
      .finally(() => {
        setLoadingSave(false);
      });
    }
  }

  return (
    <>
      <Container>
        <Row className="justify-content-md-center align-items-center">
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4" className="text-center">จัดการหน้า Login</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form noValidate validated={validated} onSubmit={formSubmit}>
                  <Form.Group>
                    <label>ชื่อหน่วยงาน</label>
                    <Form.Control type="text" name="style_name" value={form.style_name} placeholder="" onChange={handleFormInputChange}></Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <label>รูป Cover</label>
                    <div>
                      <label className="btn btn-primary btn-fill mr-sm-2">
                        <i className="fas fa-paperclip"></i> แนบรูป
                        <Form.File.Input name="style_cover" onChange={handleFormInputChange} accept=".jpg,.jpeg,.png,.gif" className="d-none" />
                      </label>
                    </div>
                    {form.style_cover.url && <div>
                      <img src={form.style_cover.url} className="img-fluid" />
                    </div>}
                  </Form.Group>
                  <Form.Group>
                    <label>สีปุ่ม</label>
                    <Form.Control type="text" name="style_btn_color" value={form.style_btn_color} placeholder="ใส่เบอร์สี เช่น #1D62F0" onChange={handleFormInputChange}></Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <label>รูป Footer</label>
                    <div>
                      <label className="btn btn-primary btn-fill mr-sm-2">
                        <i className="fas fa-paperclip"></i> แนบรูป
                        <Form.File.Input name="style_footer" onChange={handleFormInputChange} accept=".jpg,.jpeg,.png,.gif" className="d-none" />
                      </label>
                    </div>
                    {form.style_footer.url && <div>
                      <img src={form.style_footer.url} className="img-fluid" />
                    </div>}
                  </Form.Group>
                  <div className="text-center">
                    <ButtonLoading type="submit" icon="fas fa-check" className="btn btn-info btn-fill" loading={loadingSave}>บันทึก</ButtonLoading>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {
        alert.show && <SweetAlert
                        title={alert.text}
                        onConfirm={() => setAlert({"show": false})}
                        btnSize="md"
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}} />
      }
    </>
  );
}

export default Style;
