import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, useParams, generatePath } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import ChartistGraph from "react-chartist";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import AuthenContext from "../models/AuthenContext";

function Dashboard() {
  const {authen, setIsLogin} = useContext(AuthenContext);

  const [alert, setAlert] = useState({
    "show": false,
    "text": ""
  });

  const [userStat, setUserStat] = useState({
    "count": ""
  });
  const [docStat, setDocStat] = useState({
    "out": "",
    "in": "",
    "alert": ""
  });
  const [chartDoc, setChartDoc] = useState({
    "labels": [],
    "series": [],
    "legends": []
  });
  const [userlogs, setUserLogs] = useState([]);

  useEffect(() => {
    loadUserStat();
    loadDocStat();
    loadChartDoc();
    loadUserLogs();
  }, []);

  const loadUserStat = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/user.stat", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authen.token
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setUserStat(data);
      }
      else if(status == -1) {
        setIsLogin(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadDocStat = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/doctype.stat", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authen.token
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setDocStat({
          "out": data[3].count,
          "in": data[1].count,
          "alert": data[2].count + data[4].count
        });
      }
      else if(status == -1) {
        setIsLogin(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadChartDoc = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/docmonth.stat", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authen.token
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setChartDoc(data);
      }
      else if(status == -1) {
        setIsLogin(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadUserLogs = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/userlog.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authen.token,
      "limit": 10
    })
    .then((res) => {
      let { status, data, page } = res.data;
      if(status == 1) {
        setUserLogs(data);
      }
      else if(status == -1) {
        setIsLogin(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fas fa-user text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">จำนวนผู้ใช้งาน</p>
                      <Card.Title as="h4">{userStat.count}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer></Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fas fa-envelope text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">เอกสาร (ภายนอก)</p>
                      <Card.Title as="h4">{docStat.out}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer></Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="far fa-folder-open text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">เอกสาร (ภายใน)</p>
                      <Card.Title as="h4">{docStat.in}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer></Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fas fa-bullhorn text-primary"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">คำสั่ง / ประกาศ</p>
                      <Card.Title as="h4">{docStat.alert}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer></Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h4">ภาพรวมเอกสาร</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="ct-chart" id="chartDoc">
                  <ChartistGraph
                    data={chartDoc}
                    type="Bar"
                    options={{
                      seriesBarDistance: 10,
                      axisX: {
                        showGrid: false,
                      },
                      height: "245px",
                    }}
                    responsiveOptions={[
                      [
                        "screen and (max-width: 640px)",
                        {
                          seriesBarDistance: 5,
                          axisX: {
                            labelInterpolationFnc: function (value) {
                              return value[0];
                            },
                          },
                        },
                      ],
                    ]}
                  />
                </div>
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  {chartDoc.legends.map((row, index) => (
                    <>
                      <i className={"fas fa-circle text-" + row.class}></i> {row.title} &nbsp;
                    </>
                  ))}
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="6">
            <Card className="card-tasks">
              <Card.Header>
                <Card.Title as="h4">สถานะผู้ใช้งาน</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="table-full-width">
                  <Table>
                    <tbody>
                      {userlogs.map((row, index) => (
                        <tr>
                          <td>{row.fullname}</td>
                          <td className="text-right text-success">{row.date_f}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
              <Card.Footer></Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>

      {
        alert.show && <SweetAlert
                        title={alert.text}
                        onConfirm={() => setAlert({"show": false})}
                        btnSize="md"
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}} />
      }
    </>
  );
}

export default Dashboard;
