import Index from "views/Index.js";
import Dashboard from "views/Dashboard.js";
//import UserProfile from "views/UserProfile.js";
import Login from "views/Login";
import UserType from "views/UserType";
import Position from "views/Position";
import User from "views/User";
import Choice from "views/Choice";
import ChoiceGroup from "views/ChoiceGroup";
import Stamp from "views/Stamp";
import Style from "views/Style";
import DocIn from "views/DocIn";
import DocOut from "views/DocOut";
import Password from "views/Password";
import Logout from "views/Logout";
import Test from "views/Test";

const adminRoutes = [
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/dashboard",
    name: "Dashboard",
    icon: "fas fa-home",
    component: Dashboard,
    layout: "/admin",
  },
  /*{
    path: "/adminupbean/user",
    name: "User Profile",
    icon: "nc-icon nc-circle-09",
    component: UserProfile,
    layout: "/admin",
  },*/
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/login",
    name: "Login",
    icon: "",
    component: Login,
    layout: "/blank",
    redirect: true,
  },
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/usertype",
    name: "ประเภทผู้ใช้งาน",
    icon: "fas fa-user-tag",
    component: UserType,
    layout: "/admin",
  },
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/position",
    name: "ตำแหน่ง",
    icon: "fas fa-layer-group",
    component: Position,
    layout: "/admin",
  },
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/user",
    name: "ผู้ใช้งาน",
    icon: "fas fa-user",
    component: User,
    layout: "/admin",
  },
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/choice",
    name: "ตัวเลือกการอนุมัติ",
    icon: "fas fa-check-square",
    component: Choice,
    layout: "/admin",
  },
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/choicegroup",
    name: "รูปแบบ",
    icon: "",
    component: ChoiceGroup,
    layout: "/admin",
    redirect: true,
  },
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/stamp",
    name: "ตราประทับ",
    icon: "fas fa-stamp",
    component: Stamp,
    layout: "/admin",
  },
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/style",
    name: "จัดการหน้า Login",
    icon: "fas fa-palette",
    component: Style,
    layout: "/admin",
  },
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/docin",
    name: "เอกสารภายใน",
    icon: "fas fa-file-import",
    component: DocIn,
    layout: "/admin",
  },
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/docout",
    name: "เอกสารภายนอก",
    icon: "fas fa-file-export",
    component: DocOut,
    layout: "/admin",
  },
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/password",
    name: "เปลี่ยนรหัสผ่าน",
    icon: "fas fa-key",
    component: Password,
    layout: "/admin",
  },
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/logout",
    name: "ออกจากระบบ",
    icon: "fas fa-sign-out-alt",
    component: Logout,
    layout: "/admin",
  },
  {
    path: process.env.REACT_APP_ADMIN_PATH + "/test",
    name: "Test",
    icon: "",
    component: Test,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/",
    name: "Index",
    icon: "",
    component: Index,
    layout: "/blank",
    redirect: true,
  },
];

export default adminRoutes;
