import Dashboard from "views/user/Dashboard.js";
import Login from "views/user/Login";
import Password from "views/user/Password";
import Doc from "views/user/Doc";
import Doccmd from "views/user/Doccmd";
import Docin from "views/user/Docin";
import Logout from "views/user/Logout";
import Test from "views/Test";

const userRoutes = [
  {
    path: process.env.REACT_APP_USER_PATH + "/dashboard",
    name: "Dashboard",
    icon: "fas fa-home",
    component: Dashboard,
    layout: "/user",
  },
  {
    path: process.env.REACT_APP_USER_PATH + "/login",
    name: "Login",
    icon: "",
    component: Login,
    layout: "/blank",
    redirect: true,
  },
  {
    path: process.env.REACT_APP_USER_PATH + "/password",
    name: "เปลี่ยนรหัสผ่าน",
    icon: "fas fa-key",
    component: Password,
    layout: "/user",
  },
  {
    path: process.env.REACT_APP_USER_PATH + "/doc",
    name: "สร้างเอกสาร",
    icon: "fas fa-file",
    component: Doc,
    layout: "/user",
  },
  {
    path: process.env.REACT_APP_USER_PATH + "/doccmd",
    name: "คำสั่ง",
    icon: "fas fa-stamp",
    component: Doccmd,
    layout: "/user",
  },
  {
    path: process.env.REACT_APP_USER_PATH + "/docin",
    name: "รับเอกสาร",
    icon: "fas fa-inbox",
    component: Docin,
    layout: "/user",
  },
  {
    path: process.env.REACT_APP_USER_PATH + "/logout",
    name: "ออกจากระบบ",
    icon: "fas fa-sign-out-alt",
    component: Logout,
    layout: "/user",
  },
  {
    path: process.env.REACT_APP_USER_PATH + "/test",
    name: "Test",
    icon: "",
    component: Test,
    layout: "/user",
    redirect: true,
  },
];

export default userRoutes;
