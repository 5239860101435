import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  Modal
} from "react-bootstrap";

import AuthenContext from "../models/AuthenContext";
import ButtonLoading from "../components/Controls/ButtonLoading";
import Page from "../components/Controls/Page";
import Draw from "../components/Controls/Draw";
import ImageLib from "../libs/ImageLib";

function User(props) {
  const history = useHistory();

  const {authen, setIsLogin} = useContext(AuthenContext);

  const [showForm, setShowForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formTitle, setFormTitle] = useState("");

  const [alert, setAlert] = useState({
    "show": false,
    "text": ""
  });

  const [confirm, setConfirm] = useState({
    "show": false,
    "text": "",
    "confirmBtnText": "",
    "onConfirm": () => {},
    "cancelBtnText": "",
    "onCancel": () => {}
  });

  const [search, setSearch] = useState({
    "keyword": ""
  });

  const handleSearchInputChange = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value
    });

    setPages({
      ...pages,
      "activePage": 1
    });
  }

  const initForm = {
    "user_id": "",
    "fullname": "",
    "position_id": "",
    "school_usertype_id": "",
    "school_user": "",
    "school_pass": "",
    "school_user_status": 0,
    "sign_type": 0,
    "sign_data": null,
    "sign_ref": null,
    "sign_file": null,
    "sign_change": false
  };

  let activePage = new URLSearchParams(props.location.search).get("page");
  activePage = activePage === null ? 1 : parseInt(activePage);

  const [locationKeys, setLocationKeys] = useState([]);
  const [table, setTable] = useState([]);
  const [pages, setPages] = useState({
    "activePage": activePage
  });

  const handlePageChange = (e, page) => {
    history.push("?page=" + page);
  }

  const [form, setForm] = useState(initForm);
  const [loadingSave, setLoadingSave] = useState(false);
  const [clearDrawSign, setClearDrawSign] = useState(false);
  const canvasSign = useRef();
  const canvasPreviewSign = useRef();

  const [position, setPosition] = useState([]);
  const [usertype, setUserType] = useState([]);

  useEffect(() => {
    loadPosition();
    loadUserType();
  }, []);

  useEffect(() => {
    loadTable();
  }, [search, pages.activePage]);

  useEffect(() => {
    setPages({
      ...pages,
      "activePage": activePage
    });

    return history.listen(location => {
      if(history.action === "PUSH") {
        setLocationKeys([location.key]);
      }
  
      if(history.action === "POP") {
        if(locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          // Handle forward event
        }
        else {
          setLocationKeys((keys) => [location.key, ...keys]);
          // Handle back event
        }
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    if(showForm && form.sign_data) {
      let canvas = form.sign_type == 0 ? canvasSign.current : canvasPreviewSign.current;
      let ctx = canvas.getContext("2d");
      let image = new Image();
      image.onload = () => {
        ctx.drawImage(image, 0, 0);
      };
      image.src = form.sign_data;
    }
  }, [showForm]);

  const handleFormInputChange = (e) => {
    let key = e.target.name, value;
    
    if(e.target.type == "file") {
      let file = e.target.files[0];
      let fr = new FileReader();
      fr.onload = () => {
        value = {
          "name": file.name,
          "size": file.size,
          "type": file.type,
          "base64": fr.result
        };

        if(key == "sign_file") {
          let canvas = canvasPreviewSign.current;
          let ctx = canvas.getContext("2d");
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          let image = new Image();
          image.onload = () => {
            ImageLib.drawImageScaleCanvas(image, ctx);

            setForm({
              ...form,
              [key]: value,
              "sign_change": true,
              "sign_data": canvasPreviewSign.current.toDataURL()
            });
          };
          image.src = fr.result;
        }
        else {
          setForm({
            ...form,
            [key]: value
          });
        }
      };
      fr.readAsDataURL(file);
      return;
    }
    else {
      value = e.target.value;
    }

    setForm({
      ...form,
      [key]: value
    });
  }

  const handleDrawingEnd = (e) => {
    setForm({
      ...form,
      "sign_change": true,
      "sign_data": canvasSign.current.toDataURL()
    });
  }

  const loadTable = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/user.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authen.token,
      "keyword": search.keyword,
      "page": pages.activePage
    })
    .then((res) => {
      let { status, data, page } = res.data;
      if(status == 1) {
        setPages(page);
        setTable(data.map((row) => {
          return {...row, loadingEdit: false, loadingDelete: false};
        }));
      }
      else if(status == -1) {
        setIsLogin(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadEdit = (index) => {
    let id = table[index].id;
    
    axios.post(process.env.REACT_APP_API_URL + "/school/user.get", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authen.token,
      "id": id
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setForm(data);
        setValidated(false);
        setFormTitle("แก้ไขผู้ใช้งาน");
        setShowForm(true);
      }
      else if(status == -1) {
        setIsLogin(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    })
    .finally(() => {
      table[index].loadingEdit = false;
    });
  }

  const addData = (e) => {
    setForm(initForm);
    setValidated(false);
    setFormTitle("เพิ่มผู้ใช้งาน");
    setShowForm(true);
  }

  const editData = (e, index) => {
    e.preventDefault();
    table[index].loadingEdit = true;
    loadEdit(index);
  }

  const deleteData = (e, index) => {
    e.preventDefault();
    table[index].loadingDelete = true;
    setConfirm({
      "show": true,
      "text": `ต้องการลบ "${table[index].school_user}" ใช่หรือไม่`,
      "confirmBtnText": "ลบ",
      "onConfirm": () => {
        let id = table[index].id;

        axios.post(process.env.REACT_APP_API_URL + "/school/user.delete", {
          "api_key": process.env.REACT_APP_API_KEY,
          "token": authen.token,
          "id": id
        })
        .then((res) => {
          let { status, data } = res.data;
          if(status == 1) {
            loadTable();
          }
          else if(status == -1) {
            setIsLogin(false);
          }
          else {
            setAlert({
              "show": true,
              "text": res.data.msg
            });
          }
        })
        .catch((ex) => {
          console.log(ex);
          setAlert({
            "show": true,
            "text": process.env.REACT_APP_SERVER_NOT_CONNECT
          });
        })
        .finally(() => {
          table[index].loadingDelete = false;
        });

        setConfirm({"show": false});
      },
      "cancelBtnText": "ยกเลิก",
      "onCancel": () => {
        setConfirm({"show": false});
        table[index].loadingDelete = false;
      }
    });
  }

  const formSubmit = (e) => {
    e.preventDefault();

    let is_valid = false;
    if(e.currentTarget.checkValidity() === false) {
      is_valid = false;
      e.stopPropagation();
    }
    else {
      is_valid = true;
    }

    setValidated(true);

    if(is_valid) {
      setLoadingSave(true);

      axios.post(process.env.REACT_APP_API_URL + "/school/user.set", {
        "api_key": process.env.REACT_APP_API_KEY,
        "token": authen.token,
        ...form
      })
      .then((res) => {
        let { status, data } = res.data;
        if(status == 1) {
          loadTable();
          setShowForm(false);
        }
        else {
          setAlert({
            "show": true,
            "text": res.data.msg
          });
        }
      })
      .catch((ex) => {
        console.log(ex);
      })
      .finally(() => {
        setLoadingSave(false);
      });
    }
  }

  const loadPosition = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/position.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authen.token,
      "order": "position_name",
      "limit": 1000
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setPosition(data);
      }
      else if(status == -1) {
        setIsLogin(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  const loadUserType = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/usertype.list", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": authen.token,
      "order": "usertype_name",
      "limit": 1000
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setUserType(data);
      }
      else if(status == -1) {
        setIsLogin(false);
      }
      else {
        setAlert({
          "show": true,
          "text": res.data.msg
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
      setAlert({
        "show": true,
        "text": process.env.REACT_APP_SERVER_NOT_CONNECT
      });
    });
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Row>
                  <Col md="6">
                    <Form inline>
                      <Form.Group>
                        <Form.Label className="mr-sm-2">ค้นหา</Form.Label>
                        <Form.Control type="text" name="keyword" value={search.keyword} placeholder="ค้นหา" onChange={handleSearchInputChange}></Form.Control>
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col md="6">
                    <Button type="button" variant="info" className="btn-fill float-right" onClick={addData}><i className="fas fa-plus"></i> เพิ่มผู้ใช้งาน</Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th width="80">ลำดับ</th>
                      <th>ชื่อผู้ใช้งาน</th>
                      <th width="200">ประเภทผู้ใช้งาน</th>
                      <th width="200">อีเมล์</th>
                      <th width="100">สถานะ</th>
                      <th width="120"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {table.map((row, index) => (
                      <tr key={index}>
                        <td className="text-center">{row.no}</td>
                        <td>{row.fullname}</td>
                        <td>{row.usertype_name}</td>
                        <td>{row.school_user}</td>
                        <td className="text-center">{row.status_text}</td>
                        <td className="text-center btn-manage-group">
                          <ButtonLoading type="link" icon="fas fa-pen" className="btn-manage" loading={row.loadingEdit} onClick={e => editData(e, index)}>แก้ไข</ButtonLoading>
                          <ButtonLoading type="link" icon="fas fa-times" className="btn-manage" loading={row.loadingDelete} onClick={e => deleteData(e, index)}>ลบ</ButtonLoading>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Page {...pages} onClick={handlePageChange} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        backdrop="static"
        show={showForm}
        onHide={() => setShowForm(false)}
      >
        <Form noValidate validated={validated} onSubmit={formSubmit}>
          <Modal.Header className="justify-content-center">
            <Modal.Title>{formTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row}>
              <Form.Label column sm="2">ชื่อผู้ใช้งาน</Form.Label>
              <Col sm="10">
                <Form.Control type="text" name="fullname" value={form.fullname} onChange={handleFormInputChange} required></Form.Control>
                <Form.Control.Feedback type="invalid">กรุณาป้อน ชื่อผู้ใช้งาน</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">ตำแหน่ง</Form.Label>
              <Col sm="10">
                <Form.Control as="select" name="position_id" value={form.position_id} onChange={handleFormInputChange} required>
                  <option value=""></option>
                  {position.map((row, index) => <option value={row.id} key={index}>{row.position_name}</option>)}
                </Form.Control>
                <Form.Control.Feedback type="invalid">กรุณาป้อน ตำแหน่ง</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">ประเภทผู้ใช้งาน</Form.Label>
              <Col sm="10">
                <Form.Control as="select" name="school_usertype_id" value={form.school_usertype_id} onChange={handleFormInputChange} required>
                  <option value=""></option>
                  {usertype.map((row, index) => <option value={row.id} key={index}>{row.usertype_name}</option>)}
                </Form.Control>
                <Form.Control.Feedback type="invalid">กรุณาป้อน ประเภทผู้ใช้งาน</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">อีเมล์</Form.Label>
              <Col sm="10">
                <Form.Control type="text" name="school_user" value={form.school_user} onChange={handleFormInputChange} required></Form.Control>
                <Form.Control.Feedback type="invalid">กรุณาป้อน อีเมล์</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">รหัสผ่าน</Form.Label>
              <Col sm="10">
                <Form.Control type="text" name="school_pass" value={form.school_pass} onChange={handleFormInputChange} required></Form.Control>
                <Form.Control.Feedback type="invalid">กรุณาป้อน รหัสผ่าน</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">สถานะ</Form.Label>
              <Col sm="10">
                <Row className="justify-content mt-1">
                  <Col sm="auto">
                    <Form.Check type="radio" id="school_user_status_1" name="school_user_status" value="1" label="ใช้งาน" checked={form.school_user_status == 1} onChange={handleFormInputChange} custom />
                  </Col>
                  <Col sm="auto">
                    <Form.Check type="radio" id="school_user_status_0" name="school_user_status" value="0" label="ระงับใช้งาน" checked={form.school_user_status == 0} onChange={handleFormInputChange} custom />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">ลายเซ็น</Form.Label>
              <Col sm="10">
                <Row className="justify-content mt-1">
                  <Col sm="auto">
                    <Form.Check type="radio" id="sign_type_0" name="sign_type" value="0" label="เซ็นเก็บไว้" checked={form.sign_type == 0} onChange={handleFormInputChange} custom />
                  </Col>
                  <Col sm="auto">
                    <Form.Check type="radio" id="sign_type_1" name="sign_type" value="1" label="แนบไฟล์" checked={form.sign_type == 1} onChange={handleFormInputChange} custom />
                  </Col>
                </Row>
                <div className="mt-2">
                  <div className={"w-100" + (form.sign_type != 0 ? " d-none" : "")}>
                    <div className="box-sign-wrap" style={{"width": "482px"}}>
                      <div className="box-sign-mark">เซ็นลายเซ็นที่นี่</div>
                      <Draw id="draw-sign" width="480" height="200" ref={canvasSign} clearDraw={clearDrawSign} setClearDraw={setClearDrawSign} onDrawingEnd={handleDrawingEnd} color="#00f" className="box-sign"></Draw>
                    </div>
                    <Button type="button" variant="danger" className="btn-fill" onClick={() => setClearDrawSign(true)}><i className="fas fa-eraser"></i> ล้าง</Button>
                  </div>
                  <div className={"w-100" + (form.sign_type != 1 ? " d-none" : "")}>
                    <div className="box-sign-wrap" style={{"width": "482px"}}>
                      <div className="box-sign-mark"><i className="far fa-image box-sign-icon"></i></div>
                      <canvas id="preview-sign" width="480" height="200" ref={canvasPreviewSign} className="box-sign"></canvas>
                    </div>
                    <label className="btn btn-primary btn-fill mr-sm-2">
                      <i className="fas fa-paperclip"></i> แนบลายเซ็น
                      <Form.File.Input name="sign_file" onChange={handleFormInputChange} accept=".jpg,.jpeg,.png,.gif" className="d-none" />
                    </label>
                  </div>
                </div>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <ButtonLoading type="submit" icon="fas fa-check" className="btn btn-primary btn-fill mr-sm-2" loading={loadingSave}>บันทึก</ButtonLoading>
            <Button type="button" variant="default" className="btn-fill" onClick={() => setShowForm(false)}><i className="fas fa-times"></i> ปิด</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {
        alert.show && <SweetAlert
                        title={alert.text}
                        onConfirm={() => setAlert({"show": false})}
                        btnSize="md"
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}} />
      }
      {
        confirm.show && <SweetAlert
                        title={confirm.text}
                        btnSize="md"
                        onConfirm={confirm.onConfirm}
                        confirmBtnText={confirm.confirmBtnText}
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}}
                        showCancel={true}
                        onCancel={confirm.onCancel}
                        cancelBtnText={confirm.cancelBtnText}
                        cancelBtnBsStyle="default"
                        cancelBtnCssClass="btn-fill"
                        cancelBtnStyle={{"border":"none","boxShadow":"none"}} />
      }
    </>
  );
}

export default User;
