import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  Modal
} from "react-bootstrap";

function Index(props) {
  return (
    <>
      <img src={process.env.REACT_APP_API_URL + "/images/banner1.jpg"} className="img-fluid" />
    </>
  );
}

export default Index;
