import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table,
  Modal
} from "react-bootstrap";

import Draw from "../components/Controls/Draw";

function Test(props) {
  const [clearDraw, setClearDraw] = useState(false);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Row>
                  <Col md="6">
                    
                  </Col>
                  <Col md="6">
                    
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <h4>Draw</h4>
                <Draw id="drawing-area" width="800" height="300" clearDraw={clearDraw} setClearDraw={setClearDraw} color="#000" style={{"border": "solid 1px #000"}}></Draw><br/>
                <Button type="button" variant="info" className="btn-fill" onClick={() => setClearDraw(true)}><i className="fas fa-plus"></i> ล้าง</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Test;
