import React, { Component } from "react";
import { useLocation, Route, Switch } from "react-router-dom";

function Blank({ routes }) {
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/blank") {
        return (
          <Route
            path={prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
  }, [location]);
  return (
    <>
      <div className="wrapper">
        <div className="content" ref={mainPanel}>
          <Switch>{getRoutes(routes)}</Switch>
        </div>
      </div>
    </>
  );
}

export default Blank;
