import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, useParams, generatePath } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import AuthenContext from "../models/AuthenContext";
import ButtonLoading from "../components/Controls/ButtonLoading";
import "../assets/css/login.css";

function Login(prop) {
  const {authen, setAuthen, setIsLogin} = useContext(AuthenContext);
  const [validated, setValidated] = useState(false);
  
  const [data, setData] = useState({
    "user": "",
    "password": ""
  });

  const [alert, setAlert] = useState({
    "show": false,
    "text": ""
  });

  const [style, setStyle] = useState({
    "style_name": "",
    "style_btn_color": "",
    "style_cover": "",
    "style_footer": "",
    "style_btn": {}
  });

  useEffect(() => {
    loadStyle();
  }, []);

  const handleInputChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  }

  const [loadingLogin, setLoadingLogin] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const loadStyle = () => {
    axios.post(process.env.REACT_APP_API_URL + "/school/schoolstyle.get", {
      "api_key": process.env.REACT_APP_API_KEY,
      "school": prop.match.params.school
    })
    .then((res) => {
      let { status, data } = res.data;
      if(status == 1) {
        setStyle({
          "style_btn": {
            "background-color": data.style_btn_color,
            "border-color": data.style_btn_color
          },
          ...data
        });
      }
    })
    .catch((ex) => {
      console.log(ex);
    });
  }

  const loginSubmit = (e) => {
    e.preventDefault();

    let is_valid = false;
    if(e.currentTarget.checkValidity() === false) {
      is_valid = false;
      e.stopPropagation();
    }
    else {
      is_valid = true;
    }

    setValidated(true);

    if(is_valid) {
      setLoadingLogin(true);

      axios.post(process.env.REACT_APP_API_URL + "/school/login", {
        "api_key": process.env.REACT_APP_API_KEY,
        "school": prop.match.params.school,
        "user": data.user,
        "password": data.password,
        "type": 1
      })
      .then((res) => {
        let { status, data } = res.data;
        if(status == 1) {
          localStorage.authen = JSON.stringify(data);
          setAuthen(data);
          setIsLogin(true);
          history.push(
            location.state === undefined || location.state.referrer.pathname === generatePath(process.env.REACT_APP_ADMIN_PATH, params) + "/logout"
            ? generatePath(process.env.REACT_APP_ADMIN_PATH, params) + "/dashboard"
            : location.state.referrer.pathname
          );
        }
        else {
          setAlert({
            "show": true,
            "text": res.data.msg
          });
        }
      })
      .catch((ex) => {
        console.log(ex);
      })
      .finally(() => {
        setLoadingLogin(false);
      });
    }
  }

  return (
    <>
      <Container>
        <Row className="justify-content-md-center align-items-center vh-100">
          {style.style_cover && <Col md="12" className="align-self-start">
            <img src={style.style_cover} className="img-fluid" />
          </Col>}
          <Col md="4">
            <Card style={{"margin-top": "30px"}}>
              <Card.Header>
                <Card.Title as="h4" className="text-center">Login</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form noValidate validated={validated} onSubmit={loginSubmit}>
                  <Form.Group>
                    <label>Username</label>
                    <Form.Control type="text" name="user" value={data.user} placeholder="Username" onChange={handleInputChange} required></Form.Control>
                    <Form.Control.Feedback type="invalid">กรุณาป้อน Username</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <label>Password</label>
                    <Form.Control type="password" name="password" value={data.password} placeholder="Password" onChange={handleInputChange} required></Form.Control>
                    <Form.Control.Feedback type="invalid">กรุณาป้อน Password</Form.Control.Feedback>
                  </Form.Group>
                  <ButtonLoading type="submit" icon="fas fa-key" className="btn btn-info btn-fill btn-block" loading={loadingLogin} style={style.style_btn}>Login</ButtonLoading>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          {style.style_footer && <Col md="12" className="align-self-end">
            <img src={style.style_footer} className="img-fluid" />
          </Col>}
        </Row>
      </Container>
      {
        alert.show && <SweetAlert
                        title={alert.text}
                        onConfirm={() => setAlert({"show": false})}
                        btnSize="md"
                        confirmBtnBsStyle="info"
                        confirmBtnCssClass="btn-fill"
                        confirmBtnStyle={{"border":"none","boxShadow":"none"}} />
      }
    </>
  );
}

export default Login;
