import { useHistory } from "react-router-dom";
import axios from "axios";

const Authen = {
  requireLogin: () => {
    let history = useHistory();

    if(localStorage.authen === undefined) {
      history.push("/school/login");
      return false;
    }
    else {
      let authen = JSON.parse(localStorage.authen);

      axios.post(process.env.REACT_APP_API_URL + "/school/me", {
        "api_key": process.env.REACT_APP_API_KEY,
        "token": authen.token
      })
      .then((res) => {
        let { status, data } = res.data;
        if(status == 1) {
          return true;
        }
        else {
          localStorage.removeItem("authen");
          history.push("/school/login");
          return false;
        }
      })
      .catch((ex) => {
        console.log(ex);
        localStorage.removeItem("authen");
        history.push("/school/login");
        return false;
      });
      return true;
    }
  },
  isLogin: async () => {
    if(localStorage.authen === undefined) {
      return false;
    }
    else {
      let authen = JSON.parse(localStorage.authen);

      await axios.post(process.env.REACT_APP_API_URL + "/school/me", {
        "api_key": process.env.REACT_APP_API_KEY,
        "token": authen.token
      })
      .then((res) => {
        let { status, data } = res.data;
        if(status == 1) {
          return true;
        }
        else {
          localStorage.removeItem("authen");
          return false;
        }
      })
      .catch((ex) => {
        console.log(ex);
        //localStorage.removeItem("authen");
        return false;
      });
      //return true;
    }
  },
  getProfile: (token) => {
    return axios.post(process.env.REACT_APP_API_URL + "/school/me", {
      "api_key": process.env.REACT_APP_API_KEY,
      "token": token
    });
  }
};

export default Authen;
