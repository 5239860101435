import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch, Redirect, generatePath } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/custom.css";

import SelectLayout from "./layouts/Select.js";
import AuthenContext from "./models/AuthenContext";
import Authen from "./authen";
import AuthenUser from "./authen_user";

function App() {
  const [authen, setAuthen] = useState(localStorage.authen === undefined ? undefined : JSON.parse(localStorage.authen));
  const [islogin, setIsLogin] = useState(!(localStorage.authen === undefined));
  const [renderRoute, setRenderRoute] = useState(null);

  useEffect(() => {
    if(renderRoute === null) return;

    if(localStorage.authen === undefined) {
      setIsLogin(false);
    }
    else {
      let authen = JSON.parse(localStorage.authen);
      Authen.getProfile(authen.token)
      .then((res) => {
        let { status, data } = res.data;
        if(status == 1) {
          setIsLogin(true);
          setAuthen(JSON.parse(localStorage.authen));
        }
        else {
          localStorage.removeItem("authen");
          setIsLogin(false);
        }
      })
      .catch((ex) => {
        console.log(ex);
        //localStorage.removeItem("authen");
        setIsLogin(false);
      });
    }
  }, [islogin, renderRoute]);

  const [authenUser, setAuthenUser] = useState(localStorage.authenUser === undefined ? undefined : JSON.parse(localStorage.authenUser));
  const [isloginUser, setIsLoginUser] = useState(!(localStorage.authenUser === undefined));
  const [renderRouteUser, setRenderRouteUser] = useState(null);

  useEffect(() => {
    if(renderRouteUser === null) return;

    if(localStorage.authenUser === undefined) {
      setIsLoginUser(false);
    }
    else {
      let authenUser = JSON.parse(localStorage.authenUser);
      AuthenUser.getProfile(authenUser.token)
      .then((res) => {
        let { status, data } = res.data;
        if(status == 1) {
          setIsLoginUser(true);
          setAuthenUser(JSON.parse(localStorage.authenUser));
        }
        else {
          localStorage.removeItem("authenUser");
          setIsLoginUser(false);
        }
      })
      .catch((ex) => {
        console.log(ex);
        //localStorage.removeItem("authen");
        setIsLoginUser(false);
      });
    }
  }, [isloginUser, renderRouteUser]);

  return (
    <AuthenContext.Provider value={{authen, setAuthen, setIsLogin, authenUser, setAuthenUser, setIsLoginUser}}>
      <BrowserRouter>
        <Switch>
          <Route path={process.env.REACT_APP_ADMIN_PATH + "/login"} render={(props) => <SelectLayout {...props} />} />
          <Route path={process.env.REACT_APP_ADMIN_PATH + "/:view"} render={(props) => {
            setRenderRoute(props.location.key);
            return (
              islogin
              ? <SelectLayout {...props} />
              : <Redirect to={{
                  pathname: generatePath(process.env.REACT_APP_ADMIN_PATH + "/login", props.match.params),
                  state: { referrer: props.location }
                }} />
            );
          }} />
          <Redirect from={process.env.REACT_APP_ADMIN_PATH} to={process.env.REACT_APP_ADMIN_PATH + "/dashboard"} />
          <Route path={process.env.REACT_APP_USER_PATH + "/login"} render={(props) => <SelectLayout {...props} base="user" />} />
          <Route path={process.env.REACT_APP_USER_PATH + "/:view"} render={(props) => {
            setRenderRouteUser(props.location.key);
            return (
              isloginUser
              ? <SelectLayout {...props} base="user" />
              : <Redirect to={{
                  pathname: generatePath(process.env.REACT_APP_USER_PATH + "/login", props.match.params),
                  state: { referrer: props.location }
                }} />
            );
          }} />
          <Redirect from={process.env.REACT_APP_USER_PATH} to={process.env.REACT_APP_USER_PATH + "/dashboard"} />
          <Route path={"/"} render={(props) => <SelectLayout {...props} />} />
        </Switch>
      </BrowserRouter>
    </AuthenContext.Provider>
  );
}

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
